@charset "UTF-8";
body {
  margin: 0px;
  color: #412119;
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  background: #fcd130;
  overflow-x: hidden;
}

p,
h2,
h3,
h4,
h5,
a,
input,
label {
  font-size: 16px;
}

h1 {
  font-size: 20px;
}

input[type=submit],
input[type=button] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input {
  cursor: pointer;
  background-color: unset;
  border: unset;
}

input:focus {
  outline: 0;
}

.home {
  width: 100vw;
  height: 100vh;
  background: #fcd130;
  overflow: hidden;
}

.title_img {
  position: absolute;
  top: 110px;
  right: 80px;
  width: 6.875vw;
  z-index: 4;
}

.portfolio_all_wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 93.75vw;
  height: 94.44vh;
}
.portfolio_all_wrap .portfolio_type_tag1,
.portfolio_all_wrap .portfolio_type_tag2,
.portfolio_all_wrap .portfolio_type_tag3 {
  position: absolute;
  top: 0;
  left: -60px;
  width: 60px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  background-color: #FFFFFF;
  border-radius: 20px 0 0 20px;
  font-size: 20px;
  line-height: 20px;
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  transition: all 0.3s;
  transition-delay: z-index 3s;
}
.portfolio_all_wrap #all {
  top: 0;
  border-radius: 20px 0 0 20px !important;
  transition: all 0.3s;
}
.portfolio_all_wrap #illust {
  top: 320px;
  transition: all 0.3s;
}
.portfolio_all_wrap #design {
  top: 160px;
  transition: all 0.3s;
}

.move {
  animation-name: moveeee;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  bottom: 0;
}

@keyframes moveeee {
  0% {
    bottom: 0px;
    right: 0px;
    z-index: 1;
  }
  50% {
    bottom: 320px;
    z-index: 1;
  }
  80% {
    z-index: 3;
  }
  100% {
    bottom: 0px;
    right: 0px;
    z-index: 3;
  }
}
.portfolio_admin {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.portfolio_admin .prof_img {
  width: 37.885vw;
  z-index: 4;
  position: absolute;
  right: -27.167vw;
  bottom: 0;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
}
.portfolio_admin .prof_img_move {
  right: 0px;
}

.all_back,
.illust_back,
.design_back {
  width: 93.75vw;
  height: 94.44vh;
  background: #ffffff;
  overflow-y: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 2s;
}
.all_back .portfolio-wrap,
.illust_back .portfolio-wrap,
.design_back .portfolio-wrap {
  width: 68.7%;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  gap: 30px;
  margin-top: 60px;
  margin-left: 8.177vw;
  overflow-y: scroll;
  height: 90%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: all 1s;
}
.all_back .portfolio-wrap .lastMargin,
.illust_back .portfolio-wrap .lastMargin,
.design_back .portfolio-wrap .lastMargin {
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
}
.all_back .portfolio-wrap .lastMargin:nth-last-child(1),
.illust_back .portfolio-wrap .lastMargin:nth-last-child(1),
.design_back .portfolio-wrap .lastMargin:nth-last-child(1) {
  margin-bottom: 100px;
}
.all_back .portfolio-wrap::-webkit-scrollbar,
.illust_back .portfolio-wrap::-webkit-scrollbar,
.design_back .portfolio-wrap::-webkit-scrollbar {
  display: none;
}
.all_back .portfolio-wrap .portfolio,
.illust_back .portfolio-wrap .portfolio,
.design_back .portfolio-wrap .portfolio {
  width: 100%;
  cursor: pointer;
  opacity: 0.75;
  transition: all 0.3s;
  height: fit-content;
}
.all_back .portfolio-wrap .portfolio:hover,
.illust_back .portfolio-wrap .portfolio:hover,
.design_back .portfolio-wrap .portfolio:hover {
  opacity: 1;
}
.all_back .portfolio-wrap .portfolio:nth-last-child(1),
.illust_back .portfolio-wrap .portfolio:nth-last-child(1),
.design_back .portfolio-wrap .portfolio:nth-last-child(1) {
  margin-bottom: 200px;
}
.all_back .portfolio-wrap .portfolio img,
.illust_back .portfolio-wrap .portfolio img,
.design_back .portfolio-wrap .portfolio img {
  width: 100%;
  height: 14.6vw;
  object-fit: cover;
  object-position: top;
  background: #412119;
}
.all_back .portfolio-wrap .portfolio h2,
.illust_back .portfolio-wrap .portfolio h2,
.design_back .portfolio-wrap .portfolio h2 {
  line-height: 16px;
}
.all_back .portfolio-wrap .portfolio .meta_wrap,
.illust_back .portfolio-wrap .portfolio .meta_wrap,
.design_back .portfolio-wrap .portfolio .meta_wrap {
  display: flex;
}
.all_back .portfolio-wrap .portfolio .meta_wrap p,
.illust_back .portfolio-wrap .portfolio .meta_wrap p,
.design_back .portfolio-wrap .portfolio .meta_wrap p {
  font-size: 12px;
  line-height: 15px;
  margin: 8px 8px 8px 0;
  color: #BCBCBC;
}

.admin_side_icon {
  content: "";
  background-image: url(../images/camera-retro.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  left: -50px;
  position: absolute;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
}

.signin {
  width: 300px;
  padding: 20px;
  background: #ffffff;
  margin: 100px auto;
  border-radius: 10px;
}
.signin label {
  display: block;
  width: 100%;
}
.signin label input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  display: block;
  color: #412119;
  border: 2px solid #412119;
  border-radius: 5px;
}
.signin button {
  width: 99%;
  margin: 30px auto;
  display: block;
  appearance: none;
  border: unset;
  background: #412119;
  line-height: 40px;
  color: white;
  border-radius: 5px;
}

.App {
  padding: 50px 0;
}

.img_upload {
  width: 1000px;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 20px 20px 20px;
  padding: 60px 200px;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
.img_upload .img_wrap {
  width: 100%;
  height: 300px;
  object-fit: cover;
  overflow-y: scroll;
}
.img_upload .img_wrap img {
  width: 100%;
  object-fit: cover;
}
.img_upload span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 3px #fcd130;
  margin: 20px auto;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.img_upload input {
  width: 100%;
  display: none;
}
.img_upload button {
  margin: 10px 0;
  width: 100%;
}

.progress-bar {
  height: 5px;
  margin-top: 10px;
  width: 90%;
  background: #fcd130;
}

.backdrop {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  z-index: 6;
  overflow-y: scroll;
}
.backdrop img {
  display: block;
  margin: 60px auto 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 44px 40px;
  width: 800px;
  border-radius: 10px;
}
.backdrop span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border: 2px solid #fcd130;
  border-radius: 20px;
  width: 200px;
  cursor: pointer;
  height: 10px;
  z-index: 100;
  margin: 0 auto;
}

.monyo {
  position: absolute;
  top: 38px;
  left: 7.177vw;
  width: 69%;
  z-index: 4;
}

.portGet-wrap {
  width: 1000px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 20px 20px 20px;
  padding: 60px 200px;
  position: absolute;
  top: 80px;
  left: 50%;
  height: max-content;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
.portGet-wrap .admin_side_icon {
  content: "";
  background-image: url(../images/book.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  left: -50px;
  top: 117px;
  position: absolute;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
}
.portGet-wrap .portfolio-wrap {
  width: 100%;
  height: 890px;
  overflow-x: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
  position: relative;
}
.portGet-wrap .portfolio-wrap::-webkit-scrollbar {
  display: none;
}
.portGet-wrap .portfolio-wrap .portfolio {
  position: relative;
  width: 48%;
}
.portGet-wrap .portfolio-wrap .portfolio span {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  color: #ffffff;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  content: "";
  background-image: url(../images/trash-alt.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}
.portGet-wrap .portfolio-wrap .portfolio .edit {
  left: 45px;
  background-image: url(../images/edit.svg);
}
.portGet-wrap .portfolio-wrap .portfolio img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: top;
}

.img_upload_move {
  right: 0px;
  z-index: 5;
}

.single_backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
}
.single_backdrop .portfolio_single_back {
  width: 1000px;
  padding: 60px 200px;
  background: #ffffff;
  margin: 100px auto;
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 20px;
  position: relative;
}
.single_backdrop .portfolio_single_back .single_title {
  margin-bottom: 10px;
}
.single_backdrop .portfolio_single_back .close_wrap {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 41px;
  height: 43px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}
.single_backdrop .portfolio_single_back .close_wrap img {
  width: 24px;
  height: 24px;
}
.single_backdrop .portfolio_single_back .close_wrap p {
  font-size: 15px;
  letter-spacing: -1.8px;
}
.single_backdrop .portfolio_single_back .single_meta {
  display: flex;
  column-gap: 15px;
  line-height: 15px;
}
.single_backdrop .portfolio_single_back .single_meta p {
  font-size: 15px;
  color: #BCBCBC;
}
.single_backdrop .portfolio_single_back a {
  color: #BCBCBC;
  font-size: 15px;
  transition: all 0.3s;
}
.single_backdrop .portfolio_single_back a:hover {
  color: #412119;
}
.single_backdrop .portfolio_single_back .single_body {
  margin-top: 20px;
  margin-bottom: 57px;
}

.App {
  width: 100vw;
  background: #fcd130;
  height: 1240px;
}
.App .subImageGrid {
  position: absolute;
  width: 665px;
  top: 0;
  right: -665px;
  height: 100vh;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: -5px 3px 6px #00000029;
  border-radius: 20px;
  transition: 0.5s ease-in-out;
  z-index: 5;
}
.App .subImageGrid .overflow {
  width: 500px;
  height: 76vh;
  overflow-y: scroll;
  margin: 130px auto 0;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 20px;
}
.App .subImageGrid .overflow::-webkit-scrollbar {
  display: none;
}
.App .subImageGrid .overflow img {
  width: 215px;
  height: 154px;
  object-fit: cover;
  object-position: top;
  background: #412119;
}
.App .subImageGrid_move {
  right: 0px;
}
.App .signout {
  width: 430px;
  height: 72px;
  background: #412119 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  font: normal normal bold 16px/20px Tsukushi A Round Gothic;
  color: #F5F5F5;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 286px;
  position: relative;
  top: 800px;
  margin: 0 auto;
  left: 333px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.App .signout:hover {
  left: 485px;
}
.App .image_select {
  position: relative;
  width: 100%;
  height: 160px;
  border: 2px solid #41211999;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow-y: scroll;
  margin-bottom: 24px;
}
.App .image_select::-webkit-scrollbar {
  display: none;
}
.App .image_select .sum {
  width: 70%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
.App .image_select .image_icon_wrap {
  text-align: center;
}
.App .image_select .image_icon_wrap .image_icon {
  fill: #41211999;
  width: 51px;
  height: 40px;
}
.App .image_select .image_icon_wrap .image_icon_text {
  font: normal normal bold 16px/16px Tsukushi A Round Gothic;
  letter-spacing: 0px;
  color: #41211999;
}
.App .create_tag,
.App .image_tag,
.App .edit_tag {
  position: absolute;
  top: 0;
  left: -60px;
  width: 60px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  background-color: #FFFFFF;
  border-radius: 20px 0 0 20px;
  font-size: 20px;
  line-height: 20px;
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  transition: all 0.3s;
  transition-delay: z-index 3s;
}
.App .edit_tag {
  top: 180px;
}
.App .image_tag {
  top: 360px;
}
.App .create-wrap {
  width: 1000px;
  height: fit-content;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 20px 20px 20px;
  padding: 60px 200px;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
.App .create-wrap label,
.App .create-wrap input,
.App .create-wrap textarea {
  display: block;
  width: 100%;
  height: 30px;
  color: #412119;
}
.App .create-wrap label {
  line-height: 16px;
  font-size: 16px;
  height: auto;
  margin-bottom: 8px;
}
.App .create-wrap label span {
  font-size: 12px;
  margin-left: 16px;
}
.App .create-wrap input,
.App .create-wrap textarea {
  background: #FFFFFF;
  appearance: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #41211999;
  border-radius: 5px;
  height: 40px;
}
.App .create-wrap input {
  margin-bottom: 24px;
}
.App .create-wrap textarea {
  height: 120px;
  margin-bottom: 24px;
}
.App .create-wrap #cover-image-field {
  display: none;
}
.App .create-wrap .radio_field {
  margin-bottom: 24px;
}
.App .create-wrap .radio_wrap {
  display: inline-flex;
  margin-right: 40px;
  width: fit-content;
  cursor: pointer;
}
.App .create-wrap .radio_wrap .type_radio {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #41211999;
  border-radius: 2px;
  width: 17px;
  height: 17px;
  display: inline-block;
  margin: 0;
  position: relative;
  margin-right: 8px;
}
.App .create-wrap .radio_wrap .type_radio:checked {
  content: "";
  display: block;
  border-radius: 2px;
  width: 17px;
  height: 17px;
  background-color: #412119;
  opacity: 0;
  background-image: url(../images/check.svg);
  background-size: 10px 6px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
}
.App .create-wrap .create-image-wrap {
  width: 100%;
  position: relative;
  margin: 62px auto 0;
  left: -20px;
}
.App .create-wrap .create-image-wrap .create-image {
  height: 58px;
  object-fit: cover;
  margin: 10px auto;
  display: block;
  padding: 0 100px;
  cursor: pointer;
}
.App .create-wrap .create-image-wrap .create-image-text {
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  opacity: 1;
  transition: 0.3s;
}
.App .img_upload {
  padding: 60px 150px;
}
.App .img_upload .overflow {
  width: 100%;
  display: grid;
  grid-template-columns: 215px 215px 215px;
  justify-content: space-between;
  row-gap: 20px;
  overflow-y: scroll;
  height: fit-content;
}
.App .img_upload .overflow::-webkit-scrollbar {
  /* Chrome, Safari 対応 */
  display: none;
}
.App .img_upload .overflow .img-wrap {
  cursor: pointer;
  position: relative;
  height: fit-content;
}
.App .img_upload .overflow .img-wrap img {
  width: 100%;
  height: 154px;
  object-fit: cover;
}
.App .img_upload .overflow .img-wrap .imagesDelete {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  color: #ffffff;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  content: "";
  background-image: url(../images/trash-alt.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  z-index: 5;
}
.App .move {
  animation-name: movee;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  top: 80px;
}
@keyframes movee {
  0% {
    top: 80px;
    z-index: 1;
  }
  50% {
    top: -120px;
    z-index: 1;
  }
  80% {
    z-index: 3;
  }
  100% {
    top: 80px;
    z-index: 3;
  }
}